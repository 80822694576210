<template>
<div>
  <div class="top">
    <particules class="particules"/>
    <CardSection class="maxwidth cardSection scroll-hidden" v-scroll="'scroll-hidden'"  title="Our Works, Your Vision" text="We work and collaborate with global clients to establish and create digital products that align with business objectives and user needs." buttonText="Start a project" href="mailto:hello@webeasy.pro"/>
  </div>
  <div class="container">
    <WorkCards class="maxwidth" :projects="projects"/>
  </div>
  <div class="bot">
    <FooterComponent class="maxwidth"/>
  </div>
</div>
</template>

<script>
import { useHead } from '@vueuse/head';
import WorkCards from '../components/WorkCardsPageWorks.vue';
import CardSection from '../components/CardSectionComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';
import particules from '@/components/particulesV2.vue';
import projects from '../data/projects.json';

export default {
  name: 'WorksPage',
  components: {
    WorkCards,
    CardSection,
    FooterComponent,
    particules,
  },
  setup() {
    const projectNames = projects.map(project => project.name).join(', ');
    const projectTypes = [...new Set(projects.map(project => project.type))].join(', ');

    useHead({
      title: 'Our Works - Webeasy',
      meta: [
        {
          name: 'description',
          content: 'Explore our recent projects and see how we helped our clients achieve their digital goals.',
        },
        {
          name: 'keywords',
          content: `Webeasy, projects, works, web development, design, ${projectNames}, ${projectTypes}, portfolio, case studies, digital solutions, creative projects, innovative designs, client success stories, custom web solutions, app development, branding projects, UI/UX case studies, responsive design projects, scalable platforms`,
        },
      ],
    });

    // Charger les données des projets
    const projectsData = projects;

    return {
      projects: projectsData,
    };
  },
};
</script>

<style scoped>
  .particules {
    position: absolute;
      pointer-events: none;
  }
.top {
  position: relative;
  background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
display: flex;
justify-content: center;
}
.bot {
  position: relative;
  background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%);
display: flex;
justify-content: center;
}
.cardSection {
  width: 95vw;
  max-width: 1920px;
}
.maxwidth {
    max-width: 1920px;
    margin-left: 4.7vw !important;
    margin-right: 4.7vw !important;
}
.container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.works-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.work-card {
  cursor: pointer;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
}
.work-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>