<template>
  <div>
    <particles class="particles" />
    <particules class="particules" />
    <div class="home-page">
      <HeroSection class="hero maxwidth scroll-hidden" v-scroll="'scroll-hidden'" />
      <ServicesSection class="maxwidth " />
      <div class="container-works ">
        <WeMakeTitle class="scroll-hidden" v-scroll="'scroll-hidden'" />
        <WorkCards class="scroll-hidden" v-scroll="'scroll-hidden'" />
      </div>
      <CarousselComponent class="scroll-hidden margin-left" v-scroll="'scroll-hidden'" />
      <FooterComponent class="maxwidth scroll-hidden" v-scroll="'scroll-hidden'" />
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import HeroSection from '@/components/HeroSection.vue';
import particles from '@/components/particles.vue';
import particules from '@/components/particules.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import WeMakeTitle from '@/components/WeMakeTitle.vue';
import WorkCards from '@/components/WorkCards.vue';
import CarousselComponent from '@/components/CarousselComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'HomePage',
  components: {
    HeroSection,
    particles,
    particules,
    ServicesSection,
    WeMakeTitle,
    WorkCards,
    CarousselComponent,
    FooterComponent,
  },
  setup() {
    useHead({
      title: 'Webeasy | Design, and Development for Your Brand',
      meta: [
        {
          name: 'description',
          content: 'From your brand identity to your website, app, or custom platform — we take care of everything. Strategy, design, development — one team, one flow, zero headaches.',
        },
        {
          name: 'keywords',
          content: 'Webeasy, website creation, web development, UI/UX design, modern websites, web performance, responsive design, SEO optimization, branding, digital transformation, custom platforms, mobile apps, e-commerce solutions, creative agency, web design agency, software development, user experience, user interface, front-end development, back-end development, full-stack development, cloud solutions, scalable web apps',
        },
        {
          name: 'author',
          content: 'Webeasy Team',
        },
        {
          property: 'og:title',
          content: 'Webeasy | Design, and Development for Your Brand',
        },
        {
          property: 'og:description',
          content: 'From your brand identity to your website, app, or custom platform — we take care of everything. Strategy, design, development — one team, one flow, zero headaches.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: 'https://www.webeasy.pro',
        },
        {
          property: 'og:image',
          content: require('@/assets/webeasy/social_webeasy.webp'),
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: 'Webeasy | Design, and Development for Your Brand',
        },
        {
          name: 'twitter:description',
          content: 'From your brand identity to your website, app, or custom platform — we take care of everything. Strategy, design, development — one team, one flow, zero headaches.',
        },
        {
          name: 'twitter:image',
          content: require('@/assets/webeasy/social_webeasy.webp'),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.webeasy.com',
        },
      ],
    });
  },
};
</script>

<style scoped>
.z-index {
  z-index: 1;
}
.full-width {
  margin-left: 4.7vw !important;
  margin-right: 4.7vw !important;
}
.container-works {
  position: relative;
  background-image: url("@/assets/backgroundwork.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background-img-container {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}
.particles {
  position: absolute;
  pointer-events: none;
}
.particules {
  position: absolute;
  pointer-events: none;
}
.hero {
  margin-top: 0px !important;
}
.maxwidth {
  max-width: 1920px !important;
  margin-left: 4.7vw !important;
  margin-right: 4.7vw !important;
}
/* .margin-left {
  margin-left: 20px;
  margin-right: 0px;
} */
.home-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}
</style>
